import { createGlobalStyle } from 'styled-components';
import { color, layout } from 'styled-system';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    ${color}
    ${layout}
  }

  #__next {
    ${layout}
  }
`;

export default GlobalStyle;
